import { gsap, ScrollTrigger } from 'gsap/all';

export default function wwd() {

    const wwdDom = document.querySelectorAll('.what-we-do');

    if(wwdDom.length > 0){
        wwdDom.forEach(el => {

            const boldText = el.querySelector('.left-start p');
            const redBanner = el.querySelector('.red-banner');
            const redBannerText = redBanner.firstElementChild;
            const content = el.querySelector('.grid-content p');


            const tl = gsap.timeline({
                // duration: 2,
                scrollTrigger: {
                    trigger: el,
                    start: 'top 50%',
                    end: 'top 80%',
                    // scrub: true,
                    // markers: true
                },
            })

            tl.from(boldText, {
                alpha: 0,
            })
            .from(redBanner, {
                scaleX: 0,
                transformOrigin: 'left center',
                duration: 0.6,
                ease: 'power3.in'
            }, '<')
            .from(redBannerText, {
                alpha: 0,
            })
            .from(content, {
                alpha: 0,
            }, '<0.2')

            ScrollTrigger.create({
                trigger: boldText,
                start: 'top bottom',
                // markers: true,
                onLeaveBack: ()=>{
                    tl.time(0).pause();
                }
            })

        })
    }

}