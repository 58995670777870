import { focusHelper, removeFocusHelper } from '../helpers/focus-helper';

class ModalLightBox extends HTMLElement {
  constructor() {
    super();
  }

  get currentState() {
    return this.getAttribute('show') === 'true';
  }

  connectedCallback() {
    this.lightbox = this.querySelector('.modal-lightbox');
    this.overlay = this.querySelector('.modal-lightbox-overlay');
    this.lightboxContent = this.querySelector('.modal-lightbox-content');
    this.templateContent = this.querySelector('template').content.cloneNode(true);
    this.images = Array.from(this.templateContent.querySelectorAll('img'));
    this.buttons = Array.from(document.querySelectorAll('.button-modal-lightbox'));
    this.hideOverflow = this.hasAttribute('data-hide-overflow');
    this.buttons.forEach((button) => button.addEventListener('click', this.open.bind(this)));
  }

  open(event) {
    this.removeAttribute('style');
    const trigger = event.target;
    const queryImg = trigger.nextElementSibling.id;
    if (!this.currentState) {
      if (this.hideOverflow) document.body.style.overflow = 'hidden';
      this.setAttribute('show', 'true');
      trigger.setAttribute('aria-expanded', 'true');
      this.lightbox.removeAttribute('aria-hidden');
      this.lightbox.addEventListener(
        'transitionend',
        (event) => {
          this.getTemplateImage(queryImg);
          this.eventsOnOpen();
        },
        { once: true }
      );
    }
  }

  close() {
    if (this.currentState) {
      this.removeContent();
    }
  }

  getTemplateImage(query) {
    const templateImage = this.images.filter((image) => image.dataset.ref === query);
    const image = templateImage[0];
    this.lightboxContent.appendChild(image);
    //this.lightboxContent.classList.add('visible');
    focusHelper(this);
  }

  eventsOnOpen() {
    window.addEventListener('keydown', this.onKeydown.bind(this));
    window.addEventListener('mousedown', this.onBodyClick.bind(this));
  }

  eventsOnClose() {
    window.removeEventListener('keydown', this.onKeydown.bind(this));
    window.removeEventListener('mousedown', this.onBodyClick.bind(this));
  }

  onKeydown(event) {
    if (!this.currentState) return;
    if (event.code.toUpperCase() !== 'ESCAPE') return;
    const component = event.target.closest('modal-lightbox[show="true"]');
    if (!component) return;
    this.close();
    window.removeEventListener('keydown', this.onKeydown);
    window.removeEventListener('mousedown', this.onBodyClick);
  }

  onBodyClick(event) {
    if (!this.currentState) return;
    if (event.target !== this.overlay) return;
    const component = event.target.closest('modal-lightbox[show="true"]');
    if (!component) return;
    this.close();
    window.removeEventListener('keydown', this.onKeydown);
    window.removeEventListener('mousedown', this.onBodyClick);
  }

  removeContent() {
    if (this.lightboxContent.firstElementChild) {
      this.setAttribute('show', 'false');
      this.lightbox.setAttribute('aria-hidden', 'true');

      setTimeout(() => {
        this.setAttribute('style', 'z-index: -1');
        this.lightboxContent.firstElementChild.remove();
      }, 550);
    }
    this.buttons.forEach((button) => {
      if (button.getAttribute('aria-expanded') === 'true') {
        button.setAttribute('aria-expanded', 'false');
        removeFocusHelper(button);
        if (this.hideOverflow) document.body.style.overflow = 'auto';
      }
    });
  }
}

customElements.define('modal-lightbox', ModalLightBox);
