import { gsap, ScrollTrigger } from 'gsap/all';

export default function panelThree(packagingDom, mmQuery, mmQueryMobile) {


    const panel = packagingDom.querySelector('.panel.two');
    const windowHeight = window.innerHeight;

    const pinTrigger = panel.querySelector('.pin-trigger')
    const verticalHeader = panel.querySelector('.vertical-header')
    const packageImgContainer = panel.querySelector('.package-img-container');
    const packageImg = packageImgContainer.querySelectorAll('img');
    const packageImgOne = packageImgContainer.querySelector('img:not(.hidden-js)');
    const packageImgHidden = packageImgContainer.querySelector('img.hidden-js');
    const header = panel.querySelector('h2');

    const panelHeight = panel.offsetHeight;

    // console.log(panelHeight)

    const mm = gsap.matchMedia()

    mm.add(mmQuery, () => {

        const imgTl = gsap.timeline({
            scrollTrigger: {
                trigger: packageImg,
                start: '-50px 100px',
                end: `${panelHeight + 300} bottom`,
                scrub: 1.5,
                // markers: true
            }
        })

        ScrollTrigger.create({
            trigger: packageImgContainer,
            start: '-50px 100px',
            end: `${panelHeight + 500} bottom`,
            pinSpacing: false,
            anticipatePin: 1,
            pin: true,
            // markers: true
        })


        gsap.to(packageImgOne, {
            opacity: 1,
            duration: 1,
            ease: 'power3.out',
            scrollTrigger: {
                toggleActions: 'play none none reverse',
                trigger: packageImg,
                start: '-50px 100px',
                // end: `${panelHeight + 600} bottom`,
            }
        })


        imgTl.addLabel('rotate')

        imgTl.to(packageImg, {
            x: 450,
            rotate: 20,
            // scale: 1.3,
            scale: 2,
            duration: 1,
            ease: 'linear',
        })

        imgTl.to(packageImgHidden, {
            alpha: 1,
            duration: .3,
            ease: 'linear',
        }, '.2')



        if (pinTrigger) {
            ScrollTrigger.create({
                trigger: pinTrigger,
                pinnedContainer: panel,
                start: '350px 50%',
                // start: `bottom-=${windowHeight/2} 50%`,
                // start: `bottom-=150px top`,
                end: 'bottom top',
                pinSpacing: false,
                anticipatePin: 1,
                pin: true,
                // markers: true
            })
        }


        ScrollTrigger.create({
            trigger: verticalHeader,
            start: `top 35%`,
            end: `${panelHeight - 400} top`,
            pinSpacing: false,
            anticipatePin: 1,
            pin: true,
            // markers: true
        })

    })

    mm.add(mmQueryMobile, ()=>{
        if (pinTrigger) {
            ScrollTrigger.create({
                trigger: pinTrigger,
                pinnedContainer: panel,
                // start: `bottom-=${windowHeight} top`,
                start: `top-=20px top`,
                end: 'bottom top',
                pinSpacing: false,
                anticipatePin: 1.5,
                pin: true,
                // markers: true
            })
        }

        gsap.to(packageImgHidden, {
            alpha: 1,
            duration: 1,
            ease: 'power3.inOut',
            scrollTrigger: {
                trigger: packageImg,
                start: '0 50%',
                toggleActions: 'play reverse play reverse',
                // markers: true,
                // end: `${panelHeight - 1200} top`,
            },
        }, '.2')
    })

    gsap.fromTo(header, { xPercent: 30 }, {
        xPercent: -30,
        scrollTrigger: {
            trigger: header,
            start: 'top bottom',
            end: '200% top',
            scrub: 2,
            // markers: true,
        }
    })
}