import { gsap } from 'gsap';
import { focusHelper, removeFocusHelper } from '../helpers/focus-helper';

class Drawer extends HTMLElement {
  constructor() {
    super();
  }

  static get observedAttributes() {
    return ['show'];
  }

  get currentState() {
    return this.getAttribute('show') === 'true';
  }

  connectedCallback() {
    this.navButton = document.getElementById('navigation-button');
    this.overlay = document.getElementById('overlay');
    this.closeButtons = Array.from(document.querySelectorAll('button[data-drawer-close]'));
    this.navItems = Array.from(document.querySelectorAll('.nav-item'));
    this.otherElementsToShow = Array.from(
      document.querySelectorAll('.logo-drawer, button[data-drawer-close]')
    );
    this.infoBlocks = Array.from(document.querySelectorAll('.info-blocks > *'));
    this.bindEvents();
  }

  attributeChangedCallback(name, old, now) {
    if (old === 'false' && now === 'true') {
      this.open();
    } else if (old === 'true' && now === 'false') {
      this.close();
    }
  }

  bindEvents() {
    this.navButton.addEventListener('click', this.onClickNavButton.bind(this));
    this.closeButtons.forEach((closeButton) =>
      closeButton.addEventListener('click', this.onClickCloseButton.bind(this))
    );
    window.addEventListener('keydown', this.onKeyDown.bind(this));
  }

  onClickNavButton(event) {
    const isExpanded = event.target.getAttribute('aria-expanded') === 'true';

    const attributeHelper = (value) => {
      this.navButton.setAttribute('aria-expanded', value);
      this.setAttribute('show', value);
    };

    isExpanded ? attributeHelper('false') : attributeHelper('true');
  }

  onClickCloseButton() {
    this.navButton.setAttribute('aria-expanded', 'false');
    this.setAttribute('show', 'false');
  }

  onKeyDown(event) {
    const drawerIsOpened = this.currentState;
    if (drawerIsOpened && event.code.toUpperCase() === 'ESCAPE') {
      this.navButton.setAttribute('aria-expanded', 'false');
      this.setAttribute('show', 'false');
    } else {
      return false;
    }
  }

  open() {
    const onCompleteHandler = () => {
      focusHelper(this);
    };

    const overlayTimeline = window.motionSafe ? gsap.timeline() : false;

    if (overlayTimeline) {
      overlayTimeline
        .set(this.overlay, { display: 'block', yPercent: 100 })
        .to(this.overlay, {
          yPercent: 0,
          duration: 0.6,
          ease: 'power2.inOut',
        })
        .to(this.otherElementsToShow, { opacity: 1, onComplete: onCompleteHandler })
        .to(this.infoBlocks, { opacity: 1 })
        .from(this.navItems, {
          x: -20,
          alpha: 0,
          duration: 0.5,
          stagger: 0.1,
          delay: 0.1,
        });
    }
  }

  close() {
    const onCompleteHandler = () => {
      removeFocusHelper(this.navButton);
    };
    const overlayCloseTimeline = window.motionSafe
      ? gsap.timeline({ onComplete: onCompleteHandler })
      : false;

    if (overlayCloseTimeline) {
      overlayCloseTimeline.to(this.overlay, {
        yPercent: 100,
        duration: 0.5,
        delay: 0.1,
        ease: 'power2.inOut',
        display: 'none',
        onComplete: () => {
          this.navItems.forEach((item) => {
            item.removeAttribute('style');
          });
          this.otherElementsToShow.forEach((item) => {
            item.removeAttribute('style');
          });
          this.infoBlocks.forEach((item) => {
            item.removeAttribute('style');
          });
        },
      });
    }
  }
}

customElements.define('menu-drawer', Drawer);
