// check if user prefers reduced motion
const prefersMotionReduce = window.matchMedia('(prefers-reduced-motion: reduce)');
document.documentElement.classList.add(
  prefersMotionReduce.matches ? 'motion-reduce' : 'motion-safe'
);
window.motionSafe = !prefersMotionReduce.matches;
prefersMotionReduce.addEventListener('change', (event) => {
  window.motionSafe = !event.matches;
  if (event.matches) {
    document.documentElement.classList.contains('motion-safe')
      ? document.documentElement.classList.replace('motion-safe', 'motion-reduce')
      : document.documentElement.classList.add('motion-safe');
  } else {
    document.documentElement.classList.contains('motion-reduce')
      ? document.documentElement.classList.replace('motion-reduce', 'motion-safe')
      : document.documentElement.classList.add('motion-reduce');
  }
});
