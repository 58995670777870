import imageGradientEffect from '../animations/image-gradient-effect';

// const getSiblingActive = (currentActive) => {
//   let allSiblings = Array.from(
//     document.querySelectorAll(`.${currentActive.classList.value.replaceAll(' ', '.')}`)
//   );
//   let siblingActive = allSiblings.filter(
//     (siblingCard) => siblingCard !== currentActive && siblingCard.classList.contains('hover')
//   );
//   console.log(siblingActive);
// };

const cardEvents = (event) => {
  let currentCard = event.target;

  switch (event.type) {
    case 'mouseenter':
      currentCard.classList.add('hover');
      imageGradientEffect({
        element: currentCard.querySelector('.layer'),
        gradient: 'linear-gradient(185deg, rgba(25, 20, 20, 0.01) 1%, rgba(25, 20, 20, 0.8) 100%)',
        duration: 0.4,
        alpha: 1,
      });
      break;
    case 'mouseleave':
      currentCard.classList.remove('hover');
      imageGradientEffect({
        element: currentCard.querySelector('.layer'),
        gradient: 'linear-gradient(185deg, rgba(25, 20, 20, 0.01) 1%, rgba(25, 20, 20, 0.0) 100%)',
        duration: 0.4,
        alpha: 0,
      });
      break;
  }
};

const gradientLoops = (card) => {
  card.addEventListener('mouseenter', cardEvents);
  card.addEventListener('mouseleave', cardEvents);
  //console.log(`.${card.classList.value.replaceAll(' ', '.')}`);
  // let cards = Array.from(
  //   document.querySelectorAll(`.${card.classList.value.replaceAll(' ', '.')}`)
  // );
  // let siblingActive = cards.filter(
  //   (siblingCard) => siblingCard !== card && siblingCard.classList.contains('hover')
  // );
  // console.log(siblingActive);
};

const gridIndexItems = Array.from(document.querySelectorAll('.grid-index-container .grid-item'));
const servicesCards = gridIndexItems.filter(
  (item) => item.getAttribute('data-index-item') === 'service'
);

if (servicesCards) {
  servicesCards.forEach(gradientLoops);
}
