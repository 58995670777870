import { gsap, ScrollTrigger } from 'gsap/all';

export default function panelThree(packagingDom, mmQuery, mmQueryMobile) {


    const panel = packagingDom.querySelector('.panel.three');

    const windowHeight = window.innerHeight;

    const pinTrigger = panel.querySelector('.pin-trigger')
    const verticalHeader = panel.querySelector('.vertical-header')
    const packageImgContainer = panel.querySelector('.package-img-container');

    // const packageImg = packageImgContainer.querySelectorAll('img');
    // const packageImgOne = packageImgContainer.querySelector('img:not(.hidden-js)');
    // const packageImgHidden = packageImgContainer.querySelector('img.hidden-js');

    const packageImg = packageImgContainer.querySelectorAll('img:not(.hidden-js)');

    const packageImgOne = packageImgContainer.querySelectorAll('img.one');
    const packageImgOneHidden = packageImgContainer.querySelector('img.one.hidden-js');
    
    const packageImgTwo = packageImgContainer.querySelectorAll('img.two');
    const packageImgTwoHidden = packageImgContainer.querySelector('img.two.hidden-js');

    const packageImgHidden = packageImgContainer.querySelectorAll('img.hidden-js');


    const header = panel.querySelector('h2');

    const panelHeight = panel.offsetHeight;

    const mm = gsap.matchMedia()

    mm.add(mmQuery, () => {
        const imgTl = gsap.timeline({
            scrollTrigger: {
                trigger: packageImgOne,
                start: '-50px 100px',
                end: `${panelHeight + 400} bottom`,
                scrub: 1.5,
                // markers: true
            }
        })

        const imgTwoTl = gsap.timeline({
            scrollTrigger: {
                trigger: packageImgOne,
                start: '-50px 100px',
                end: `${panelHeight + 400} bottom`,
                scrub: 1.5,
                // markers: true
            }
        })

        ScrollTrigger.create({
            trigger: packageImgContainer,
            start: '50% 57%',
            end: `${panelHeight} top`,
            pinSpacing: false,
            anticipatePin: 1,
            pin: true,
            // markers: true
        })

        gsap.to(packageImg, {
            opacity: 1,
            duration: 1,
            ease: 'power3.out',
            scrollTrigger: {
                toggleActions: 'play none none reverse',
                trigger: packageImg,
                start: '-50px 100px',
                // end: `${panelHeight + 600} bottom`,
            }
        })

        imgTl.to(packageImgOne, {
            x: 400,
            rotate: 15,
            scale: 1.8,
            duration: .315,
            ease: 'linear',
        })

        imgTl.to(packageImgOneHidden, {
            alpha: 1,
            duration: .1,
            ease: 'linear',
        }, '.2')

        imgTl.addLabel('toCenter')

        imgTl.to(packageImgOne, {
            x: -150,
            y: 150,
            rotate: 0,
            scale: 1,
            duration: .5,
            ease: 'linear',
        },)

        imgTl.to(packageImgOneHidden, {
            alpha: 0,
            duration: .1,
            ease: 'linear',
        }, 'toCenter')

        // imgTwoTl

        imgTwoTl.to(packageImgTwo, {
            x: 350,
            y: -330,
            rotate: -20,
            filter: 'blur(2px)',
            scale: 0.8,
            duration: .345,
            ease: 'linear',
        })

        imgTwoTl.to(packageImgTwoHidden, {
            alpha: 1,
            duration: .1,
            ease: 'linear',
        }, '.2')

        imgTwoTl.addLabel('toCenter')

        imgTwoTl.to(packageImgTwo, {
            x: -150,
            y: 150,
            rotate: 0,
            scale: 1,
            duration: .5,
            ease: 'linear',
        },)

        imgTwoTl.to(packageImgTwoHidden, {
            alpha: 0,
            duration: .1,
            ease: 'linear',
        }, 'toCenter')





        if (pinTrigger) {

            ScrollTrigger.create({
                trigger: pinTrigger,
                pinnedContainer: panel,
                // start: `bottom-=${windowHeight} top`,
                // start: `top-=150px top`,
                // start: `bottom-=${windowHeight} top`,
                start: `40% 50%`,
                end: 'bottom top',
                pinSpacing: false,
                anticipatePin: 1,
                pin: true,
                // markers: true
            })
        }


        ScrollTrigger.create({
            trigger: verticalHeader,
            start: `top 35%`,
            end: `${panelHeight - 400} top`,
            pinSpacing: false,
            anticipatePin: 1,
            pin: true,
            // markers: true
        })

    })

    mm.add(mmQueryMobile, ()=>{
        if (pinTrigger) {
            ScrollTrigger.create({
                trigger: pinTrigger,
                pinnedContainer: panel,
                // start: `bottom-=${windowHeight} top`,
                start: `top-=60px top`,
                end: 'bottom top',
                pinSpacing: false,
                anticipatePin: 1,
                pin: true,
                // markers: true
            })
        }

        gsap.to(packageImgHidden, {
            alpha: 1,
            duration: 2,
            ease: 'power3.inOut',
            scrollTrigger: {
                trigger: packageImg,
                start: '0 50%',
                toggleActions: 'play reverse play reverse',
                // markers: true,
                // end: `${panelHeight - 1200} top`,
            },
        }, '.2')
    })

    gsap.fromTo(header, { xPercent: 50 }, {
        xPercent: -10,
        scrollTrigger: {
            trigger: header,
            start: 'top bottom',
            end: '200% top',
            scrub: 2,
            // markers: true,
        }
    })

}