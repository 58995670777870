import { gsap, ScrollTrigger } from 'gsap/all'

export default function packagingFrame(packagingDom, mmQuery, mmQueryMobile) {

    const panel = packagingDom.querySelector('.panel.one');

    const windowHeight = window.innerHeight;

    const pinTrigger = panel.querySelector('.pin-trigger')
    const verticalHeader = panel.querySelector('.vertical-header')
    const header = panel.querySelector('h2');

    const panelHeight = panel.offsetHeight;

    const canvas = document.getElementById("hero-canvas");
    const canvasContainer = document.querySelector(".canvas-container");
    const context = canvas.getContext("2d");

    const width = canvas.offsetWidth;
    const height = canvas.offsetHeight;

    canvas.width = width;
    canvas.height = height;

    const frameCount = 91;

    // 7855981c-3eb7-4754-9d6e-e2da8bbcb28d-video_00001_optimized
    const currentFrame = index => (
        `/media/packaging/packaging-frames/7855981c-3eb7-4754-9d6e-e2da8bbcb28d-video_${index.toString().padStart(5, '0')}_optimized.png`
    );

    const images = []
    const airpods = {
        frame: 2
    };

    for (let i = 1; i < frameCount + 1; i++) {
        const img = new Image();
        img.src = currentFrame(i);
        // console.log(img.src)
        images.push(img);
    }


    images[frameCount-1].onload = render;

    render();

    const mm = gsap.matchMedia()

    mm.add(mmQuery, () => {

        const canvasTl = gsap.timeline({
            scrollTrigger: {
                trigger: canvasContainer,
                start: '0 top',
                end: `${panelHeight} top`,
                scrub: 1.5,
                // markers: true
            }
        })

        ScrollTrigger.create({
            trigger: canvasContainer,
            start: '0 top',
            end: `${panelHeight} top`,
            pinSpacing: false,
            anticipatePin: 1,
            pin: true,
            // markers: true
        })

        gsap.to(canvas, {
            opacity: 1,
            duration: 1,
            ease: 'power3.out',
            scrollTrigger: {
                toggleActions: 'play none none reverse',
                trigger: canvasContainer,
                start: '0 top',
                // end: `${panelHeight + 600} bottom`,
            }
        })

        canvasTl.to(canvas, {
            x: 550,
            rotate: 25,
            scale: 2,
            duration: 1,
            ease: 'linear',
        })

        gsap.to(airpods, {
            frame: frameCount - 1,
            snap: "frame",
            scrollTrigger: {
                trigger: canvasContainer,
                start: '0 top',
                end: `${panelHeight - 1200} top`,
                scrub: 0.5,
                // markers: true,
            },
            onUpdate: render // use animation onUpdate instead of scrollTrigger's onUpdate
        });

        if (pinTrigger) {
            ScrollTrigger.create({
                trigger: pinTrigger,
                pinnedContainer: panel,
                // start: `bottom-=${windowHeight} top`,
                // start: `top-=150px top`,
                start: `bottom-=${windowHeight} top`,
                end: 'bottom top',
                pinSpacing: false,
                anticipatePin: 1,
                pin: true,
                // markers: true
            })
        }


        ScrollTrigger.create({
            trigger: verticalHeader,
            start: `top 35%`,
            end: `${panelHeight - 400} top`,
            pinSpacing: false,
            anticipatePin: 1,
            pin: true,
            // markers: true
        })

    })

    mm.add(mmQueryMobile, () => {
        if (pinTrigger) {
            ScrollTrigger.create({
                trigger: pinTrigger,
                pinnedContainer: panel,
                // start: `bottom-=${windowHeight} top`,
                start: `top top`,
                end: 'bottom top',
                pinSpacing: false,
                anticipatePin: 3,
                pin: true,
                // markers: true
            })
        }

        gsap.to(airpods, {
            frame: frameCount - 1,
            snap: "frame",
            duration: 2,
            ease: 'power3.inOut',
            scrollTrigger: {
                trigger: canvasContainer,
                start: '0 50%',
                toggleActions: 'play reverse play reverse',
                // end: `${panelHeight - 1200} top`,
            },
            onUpdate: render // use animation onUpdate instead of scrollTrigger's onUpdate
        });
    })


    gsap.fromTo(header, { xPercent: 30 }, {
        xPercent: -30,
        scrollTrigger: {
            trigger: header,
            start: 'top bottom',
            end: '200% top',
            scrub: 2,
            // markers: true,
        }
    })

    function render() {
        
        context.clearRect(0, 0, canvas.width, canvas.height);


        const hRatio = canvas.width / images[airpods.frame].width;
        const vRatio = canvas.height / images[airpods.frame].height;
        const ratio = Math.min(hRatio, vRatio);
        context.drawImage(images[airpods.frame], 0, 0, images[airpods.frame].width, images[airpods.frame].height, 0, 0, images[airpods.frame].width * ratio, images[airpods.frame].height * ratio);
        
    }
}