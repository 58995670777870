import {gsap} from 'gsap';

export default function gsapCounter(){
    return gsap.registerEffect({
        name: "counter",
        extendTimeline: true,
        defaults: {
            end: 0,
            duration: 1.5,
            ease: "power1",
            increment: 1,
        },
        effect: (targets, config) => {
            const tl = gsap.timeline()
            const num = targets[0].innerText.replace(/\,/g, '')

            // console.log(num)
            // eslint-disable-next-line no-param-reassign
            config.end = config.end.replace(/\,/g, '');

            // eslint-disable-next-line no-param-reassign
            targets[0].innerText = num


            tl.to(targets, {
                duration: config.duration,
                innerText: config.end,
                // snap:{innerText:config.increment},
                modifiers: {
                    // eslint-disable-next-line func-names, object-shorthand
                    innerText: function (innerText) {
                        return gsap.utils.snap(config.increment, innerText);
                    }
                },
                // ease: config.ease
            }, 0)

            return tl
        }
    })
}