import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

//

import parallax from './parallax';
import playVideo from './playVideo';
import wwd from './wwd';
import navColor from './navColor';
import about from './about';
import typeWriter from './typewriter';
import services from './services';
import banner from './banner';
import flipImage from './flipImage';
import packaging from './packaging';
import asciiArt from '../modules/ascii-art';


gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export default function masterAnimations(){
    playVideo(gsap);
    wwd(gsap);
    about();
    parallax(gsap);
    typeWriter();
    services();
    banner();
    flipImage();
    packaging();
    asciiArt();

    navColor();
}