import { gsap } from 'gsap';

('use strict');

let arrayAccoirdions = [];

class Accordion {
  constructor(domNode, buttons, toClose) {
    this.rootEl = domNode;
    this.buttonEl = this.rootEl.querySelector('button[aria-expanded]');

    this.buttons = buttons;
    this.toClose = toClose;

    const controlsId = this.buttonEl.getAttribute('aria-controls');
    this.contentEl = document.getElementById(controlsId);

    this.open = false;
    gsap.set(this.contentEl, { height: 0, autoAlpha: 0 });

    // add event listeners
    this.buttonEl.addEventListener('click', this.onButtonClick.bind(this));
  }

  onButtonClick() {
    this.toggle(!this.open);
  }

  toggle(open) {
    
    // update the internal state
    this.open = open;
    // handle DOM updates
    this.buttons.forEach((button, i) => {
      // console.log(button.id == this.buttonEl.id)

      if (button.id == this.buttonEl.id) {
        this.buttonEl.setAttribute('aria-expanded', `${open}`);
      } else {
        arrayAccoirdions[i].open = false;
        button.setAttribute('aria-expanded', 'false');
      }
      // console.log(button)
    })


    if (open) {

      //close all accoirdions
      gsap.to(this.toClose, {
        height: 0,
        duration: 0.2,
        autoAlpha: 0,
      })

      // this.contentEl.removeAttribute('hidden');
      gsap.to(this.contentEl, {
        height: 'auto',
        duration: 0.2,
        autoAlpha: 1,
      });

    } else {
      gsap.to(this.contentEl, {
        height: 0,
        duration: 0.2,
        autoAlpha: 0,
      });
    }
  }

  // Add public open and close methods for convenience
  open() {
    this.toggle(true);
  }

  close() {
    this.toggle(false);
  }
}

// init accordions
const accordions = document.querySelectorAll('.faqs-container h3');
const buttons = document.querySelectorAll('.faqs-container button');
const toClose = document.querySelectorAll('.faqs-container .accordion-panel');

accordions.forEach((accordionEl) => {
  arrayAccoirdions.push(new Accordion(accordionEl, buttons, toClose));
});

// console.log(arrayAccoirdions)
