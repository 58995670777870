import { gsap } from 'gsap/all';

export default function banner() {

    const banners = document.querySelectorAll('.service-banner, .banner')

    if (banners.length > 0) {
        banners.forEach(el => {

            
            const caption = el.querySelector('.banner-caption')

            if(caption){
                const content = caption.querySelector('figcaption')

                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: el,
                        start: 'center 55%',
                        // markers: true,
                        toggleActions: 'play none none reverse'
                    }
                })
    
    
                gsap.set(content, {
                    alpha: 0,
                })
    
                tl.from(caption, {
                    scaleX: 0,
                    transformOrigin: 'left center',
                    ease: 'power3.in'
                })
    
                tl.to(content, {
                    alpha: 1,
                    delay: 0.5,
                }, '<')
            }
        
        })
    }

}