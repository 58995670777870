import imageGradientEffect from '../animations/image-gradient-effect';

const servicesItems = Array.from(
  document.querySelectorAll(
    'section.featured-entries .featured-entry, .our-services-cards .service, .services .service, .services .grid-item'
  )
);

const hoverCardEvent = (event) => {
  let layer = event.target.querySelector('.layer');
  switch (event.type) {
    case 'mouseenter':
      imageGradientEffect({
        element: layer,
        gradient: 'linear-gradient(185deg, rgba(25, 20, 20, 0.01) 1%, rgba(25, 20, 20, 0.8) 100%)',
        duration: 0.3,
      });
      break;
    case 'mouseleave':
      imageGradientEffect({
        element: layer,
        gradient: 'linear-gradient(185deg, rgba(25, 20, 20, 0.01) 1%, rgba(25, 20, 20, 0.0) 100%)',
        duration: 0.3,
      });
  }
};

if (servicesItems) {
  servicesItems.forEach((item) => {
    item.addEventListener('mouseenter', hoverCardEvent);
    item.addEventListener('mouseleave', hoverCardEvent);
  });
}
