import { gsap } from 'gsap';
import { scrollGetNextSection, setNextSection, loadScrollButton } from './scroll-buttons';

const scrollButton = document.getElementById('scroll-next-section');

if (scrollButton) {
  const getNextSection = scrollGetNextSection(scrollButton);

  if (getNextSection) {
    setNextSection(scrollButton);
  }
}

const overlay = document.getElementById('overlay');
const textElements = document.querySelectorAll(
  '.h1, .button:not([data-drawer-close]), .subtitle, .social-links *, .logo-heading'
);
const overlayTimeline = window.motionSafe
  ? gsap.timeline({ paused: true, smoothChildTiming: true })
  : false;

if (overlayTimeline) {
  overlayTimeline
    .set(overlay, { display: 'block', yPercent: 0 })
    .to(overlay, {
      yPercent: 100,
      duration: 1,
      delay: 0.3,
      ease: 'power2.inOut',
      display: 'none',
      // onComplete: ()=>{
      //   console.log('nav closed')
      // }
    })
    .to(
      textElements,
      {
        alpha: 1,
        stagger: 0.15,
        onComplete: () => {
          scrollButton ? loadScrollButton(scrollButton) : null;
        },
      },
      0.3
    )
    .resume();
}
