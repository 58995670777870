import { gsap, ScrollTrigger } from 'gsap/all';

import gsapCounter from './effects/gsap-counter';

export default function about() {
  const aboutHeroDom = document.getElementById('about-hero');

  if (aboutHeroDom) {
    const initialYear = aboutHeroDom.querySelector('.year-js');
    const finalYear = new Date().getFullYear().toString();
    const images = aboutHeroDom.querySelectorAll('.about-hero-image img:not(.one)');
    const header = aboutHeroDom.querySelectorAll('.header-js span');
    const initialContent = aboutHeroDom.querySelector('.about-hero-paragraph >p.main');
    const finalContent = aboutHeroDom.querySelector('.about-hero-paragraph >p:not(.main)');
    const mainNav = document.getElementById('main-header');

    // image gallery
    const galleryContainer = document.querySelector('.simple-images-wrapper');

    const mm = gsap.matchMedia();

    // presentation animations
    gsap.from('#about-hero .about-hero-image', { alpha: 0, delay: 1 });
    gsap.from('#about-hero .about-hero-paragraph', { alpha: 0, y: 50, delay: 1.2 });
    gsap.from('#about-hero .scroll-label', {
      alpha: 0,
      delay: 1.4,
      onComplete: () => {
        document.querySelector('#about-hero .scroll-label').classList.add('arrow');
      },
    });

    // scroll animations

    mm.add('(min-width: 1024px)', () => {
      const tlScroll = {
        trigger: aboutHeroDom.querySelector('.container'),
        start: 'top top',
        end: '3000 bottom',
        scrub: 2,
        invalidateOnRefresh: true,
      };

      // registering effect
      gsapCounter();

      let domWidth = initialYear.offsetWidth;
      gsap.set(initialYear, {
        width: () => domWidth + 3,
      });

      window.addEventListener('resize', () => {
        domWidth = initialYear.offsetWidth;
      });

      const counterTl = gsap.timeline({
        scrollTrigger: {
          trigger: aboutHeroDom.querySelector('.container'),
          start: 'top top',
          end: '3000 bottom',
          pin: true,
          scrub: 2,
          // markers: true,
          invalidateOnRefresh: true,
          onEnter: () => {
            gsap.set(mainNav, { yPercent: -100 });
          },
          onEnterBack: () => {
            gsap.set(mainNav, { yPercent: -100 });
          },
          onLeave: () => {
            gsap.set(mainNav, { clearProps: 'transform' });
          },
          onLeaveBack: () => {
            gsap.set(mainNav, { clearProps: 'transform' });
          },
        },
      });

      const imgsTl = gsap.timeline({
        scrollTrigger: tlScroll,
      });

      counterTl.counter(initialYear, { end: finalYear, duration: 100, ease: 'linear' });
      counterTl.to(header[0], { yPercent: 100, alpha: 0, duration: 50 }, '<10');
      counterTl.to(header[1], { y: 0, alpha: 1, duration: 50 }, '>-20');

      images.forEach((img) => {
        imgsTl.to(img, {
          x: 0,
        });
      });

      const contentTl = gsap.timeline({
        paused: true,
      });
      contentTl.to(initialContent, {
        yPercent: 100,
        alpha: 0,
        duration: 0.6,
        ease: 'power2.out',
      });
      contentTl.to(
        finalContent,
        {
          y: 0,
          alpha: 1,
          duration: 0.6,
        },
        '<'
      );

      ScrollTrigger.create({
        trigger: aboutHeroDom.querySelector('.container'),
        start: `top top`,
        end: '3000 bottom',
        invalidateOnRefresh: true,
        onUpdate: (self) => {
          if (self.progress >= 0.5 && self.direction === 1) {
            contentTl.play();
          } else if (self.progress < 0.5 && self.direction === -1) {
            contentTl.reverse();
          }
        },
      });

      // image gallery
      gsap.to(galleryContainer, {
        x: -250,
        scrollTrigger: {
          trigger: galleryContainer,
          start: 'top bottom',
          scrub: 2,
          // markers: true,
          invalidateOnRefresh: true,
        },
      });
    });

    mm.add('(max-width: 1024px)', () => {
      gsap.to(galleryContainer, {
        x: -150,
        scrollTrigger: {
          trigger: galleryContainer,
          start: 'top bottom',
          scrub: 2,
          // markers: true,
          invalidateOnRefresh: true,
        },
      });
    });

    // team members

    const line = document.querySelector('.team-members-heading span');
    const teamMember = document.querySelectorAll('.team-member-card');

    gsap.from(line, {
      scaleX: 0,
      transformOrigin: 'left center',
      ease: 'power2.out',
      duration: 1,
      scrollTrigger: {
        trigger: '#team-members',
        start: 'top 85%',
        // markers: true,
        toggleActions: 'play none none reverse',
      },
    });

    gsap.from(teamMember, {
      alpha: 0,
      ease: 'power2.out',
      duration: 1,
      stagger: 0.2,
      scrollTrigger: {
        trigger: '.team-members-cards-wrapper',
        start: 'top 80%',
        // markers: true,
        toggleActions: 'play none none reverse',
      },
    });
  }
}
