import { gsap } from 'gsap/all';

/**
 * Function used to add a flip effect into two images.
 * Those images needs to be in absolute position.
 * The parent of the card needs to hold the class: .flip-card-wrapper
 * The wrapper of the images has the class: .flip-image
 * Front image has the class: .flip-card-front
 * Back image has the class: .flip-card-back
 */
export default function flipImage() {
    const flipCards = gsap.utils.toArray('.flip-card-wrapper');

    // Validates if any flip card exists to prevent console warnings.
    if(flipCards.length) {
        flipCards.forEach(function(card) {
            // Selectors definition
            const flipImagesContainer = card.querySelector('.flip-image');
            const front = flipImagesContainer.querySelector('.flip-card-front');
            const back = flipImagesContainer.querySelector('.flip-card-back');

            gsap.set(flipImagesContainer, {
                transformStyle: "preserve-3d",
                transformPerspective: 1000
            });

            gsap.set(back, { rotationY: -180 });

            // Create the animation timeline paused.
            const tl = gsap.timeline({ paused: true })
                .to(front, { duration: 0.5, rotationY: 180 })
                    .to(back, { duration: 0.5, rotationY: 0 }, 0)
                    .to(flipImagesContainer, { z: 80 }, 0)
                    .to(flipImagesContainer, { z: 0 }, 0.5);

            // Play animation when mouse enter card
            card.addEventListener('mouseenter', () => {
                tl.play();
            });
            // Reverse animation when mouse leaves card
            card.addEventListener('mouseleave', () => {
                tl.reverse();
            });
        });
    }
}