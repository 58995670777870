import 'focus-visible';
import FontFaceObserver from 'fontfaceobserver';
import './modules/motion';
import './modules/overlay';
import './modules/menu-drawer';
import './modules/sticky-header';
import './modules/scroll-buttons';
import './modules/marquee';
import './modules/services';
import './modules/index-grid';
import './modules/accordion';
import './modules/modal-lightbox';
import cookies from './modules/cookies';

import masterAnimations from './animations/master';

// font loader
const Syne = new FontFaceObserver('Syne');
const Dela = new FontFaceObserver('Dela Gothic One');
const Silkscreen = new FontFaceObserver('Silkscreen');

Promise.all([Syne.load(), Dela.load(), Silkscreen.load()]).then(() => {
  document.documentElement.classList.add('fonts-loaded');
});

masterAnimations();
cookies();
