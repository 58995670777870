import { gsap, TextPlugin } from 'gsap/all';

gsap.registerPlugin(TextPlugin)

export default function typeWriter() {
    const typewriters = document.querySelectorAll('.typewriter-js');

    typewriters.forEach(el => {

        const element = el.querySelector('.typewriter-content');
        const text = element.innerHTML
        element.innerHTML = '';

        const delay = el.getAttribute('data-typewriterdelay') || '0'

        const cursor = document.createElement("span");
        cursor.classList.add('cursor')
        cursor.innerHTML = '|';

        el.appendChild(cursor);

        const cursorTween = gsap.fromTo(cursor, {autoAlpha: 0, x:-10}, {autoAlpha: 1, duration: 0.5, repeat: -1});

        gsap.to(element, {
            text: { value: text },
            duration: 1.6,
            ease: 'linear',
            delay,
            scrollTrigger: {
                trigger: el,
                start: 'top bottom',
                // markers: true,
            },
            // onComplete: ()=>{
            //     cursor.remove();
            // }
        })
    })
}