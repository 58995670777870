export default function cookies() {
  const COOKIE_NAME = 'cookie-consent';
  const cookiesBanner = document.getElementById('cookies');
  const cookiesAccepted = localStorage.getItem(COOKIE_NAME) === 'true';
  const cookiesButtonAccept = cookiesBanner.querySelector('#cookies-button-accept');
  // acept cookies
  const acceptCookies = () =>
    localStorage.setItem(COOKIE_NAME, 'true') && cookiesBanner.classList.remove('active');

  const onClickCookiesButtonAccept = () => {
    cookiesBanner.classList.remove('active');
    localStorage.setItem(COOKIE_NAME, 'true');
    setTimeout(() => {
      cookiesBanner.setAttribute('aria-hidden', 'true');
      cookiesBanner.remove();
    }, 400);
  };

  if (cookiesAccepted) {
    cookiesBanner.remove();
    return true;
  } else {
    setTimeout(() => {
      cookiesBanner.classList.add('active');
      cookiesButtonAccept.addEventListener('click', onClickCookiesButtonAccept);
    }, 5000);
  }
}
