import nodeMarquee from 'node-marquee';

const Marquee = (component) => {
  const id = `.${component.dataset.marqueeContainer}`;
  const resize = component.dataset.marqueeResize;
  const hoverPause = component.dataset.marqueeHoverPause === 'true' ? true : false;
  nodeMarquee({
    parent: id,
    resize: resize,
    pauseOnHover: hoverPause,
  });
};

const marqueeHomeComponent = document.querySelector(
  '#homepage-marquee-marquee-component[data-marquee-component]'
);

if (marqueeHomeComponent) {
  Marquee(marqueeHomeComponent);
}

// if (MarqueeComponent) {
//   const elementId = `#${MarqueeComponent.firstElementChild.getAttribute('id')}`;
//   nodeMarquee({
//     parent: elementId,
//     resize: 'w',
//   });
// }

// class Marquee {
//   constructor(element) {
//     super();
//     this.element = element;
//   }

//   connectedCallback() {
//     console.log('connected');
//     this.marqueeComponent = this.element;
//     this.marqueeParentId = this.marqueeComponent.firstElementChild.id;
//   }

//   createMarque() {
//     this.marquee = nodeMarquee({
//       parent: this.marqueeParentId,
//       resize: 'w',
//     });
//     this.marquee.pause();
//   }
// }

// export default Marquee;
