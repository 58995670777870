export default function parallax(gsap) {

    const parallaxItem = document.querySelectorAll('.parallax-item');

    const defaults = {
        from: -100,
        fromMobile: -50,
        to: 0,
        toMobile: 0,
        markers: false,
    }

    
    if (parallaxItem.length > 0) {
        
        parallaxItem.forEach(el => {

            const mm = gsap.matchMedia();


            const from =
                el.getAttribute('data-from') ?
                    +el.getAttribute('data-from') :
                    +defaults.from

            const fromMobile =
                el.getAttribute('data-frommobile') ?
                    +el.getAttribute('data-frommobile') :
                    false

            const to =
                el.getAttribute('data-to') ?
                    +el.getAttribute('data-to') :
                    +defaults.to

            const toMobile =
                el.getAttribute('data-tomobile') ?
                    +el.getAttribute('data-tomobile') :
                    false

            const markers = el.classList.contains('markers');
            
            if(fromMobile !== false && toMobile !== false){

                console.log(`paralax mobile for ${el}`)

                mm.add('(min-width: 641px)', ()=>{
                    gsap.fromTo(el, {
                        y: from
                    },
                    {
                        y: to,
                        scrollTrigger: {
                            trigger: el,
                            start: 'top bottom',
                            end: 'bottom top',
                            scrub: 1,
                            markers
                        }
                    })
                })

                mm.add('(max-width: 640px)', ()=>{
                    gsap.fromTo(el, {
                        y: fromMobile
                    },
                    {
                        y: toMobile,
                        scrollTrigger: {
                            trigger: el,
                            start: 'top bottom',
                            end: 'bottom top',
                            scrub: 1,
                            markers
                        }
                    })
                })
            }else{
                console.log(`paralax desktop for ${el}`)

                gsap.fromTo(el, {
                    y: from
                },
                {
                    y: to,
                    scrollTrigger: {
                        trigger: el,
                        start: 'top bottom',
                        end: 'bottom top',
                        scrub: 1,
                        markers
                    }
                })

            }


        })
    }

}