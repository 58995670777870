import { ScrollTrigger } from 'gsap/all';
import calculatePosition from '../helpers/calculatePosition';


function resetVideo(video) {
    video.pause();
    video.currentTime = 0;
}

function resetAnim(video, tl) {
    if (video.classList.contains('active')) {
        resetVideo(video)
        video.classList.remove('active')
        if (tl) {
            tl.reverse();
        }
    }
}

export default function playVideo(gsap) {

    const videoContainer = document.querySelectorAll('.video-animation');
    const { body } = document;
    const root = document.documentElement;
    const mainNav = document.getElementById('main-header');

    if (videoContainer.length > 0) {

        videoContainer.forEach(el => {

            const poster = el.querySelector('img');
            const video = el.querySelector('video.desktop');
            const videoMobile = el.querySelector('video.mobile');
            const text = el.querySelector('.text-js');
            const playButton = el.querySelector('.play-desktop');
            const closeButton = el.querySelector('.close-video');
            const playButtonMobile = el.querySelector('.play-mobile');
            const mobileControls = el.querySelector('.video-mobile-controls');

            let clone;
            let tl;

            if (playButton) {
                playButton.addEventListener('click', () => {

                    const from = calculatePosition(poster, body, root);
                    const to = calculatePosition(el, body, root);

                    tl = gsap.timeline({
                        paused: true, 
                        onComplete: () => {
                            closeButton.classList.add('active');
                            mainNav.classList.add('header-hidden');
                        }
                    });
                    clone = poster.cloneNode(true);

                    gsap.set(poster, { visibility: "hidden" })
                    gsap.set(clone, {
                        position: "absolute",
                        margin: 0,
                        objectFit: 'cover',
                        objectPosition: "center",
                        borderRadius: '20px'
                    });

                    // gsap.set(video, {
                    //     width: window.innerWidth,
                    //     height: window.innerHeight,
                    // })

                    body.appendChild(clone);

                    const style = {
                        x: to.left - from.left,
                        y: to.top - from.top,
                        width: window.innerWidth,
                        height: window.innerHeight,
                        borderRadius: 0,
                        onComplete: () => {
                            video.classList.add('active');
                            video.play();
                        }
                    };

                    gsap.set(clone, from);


                    tl.to(text, {
                        alpha: 0, onStart: () => {

                            gsap.to(window, {
                                duration: 0.3,
                                ease: 'power2.in',
                                scrollTo: { y: el, offsetY: 0 }
                            })

                        }, onReverseComplete: () => {
                            gsap.set(poster, { visibility: "visible" });
                            body.removeChild(clone);
                        }
                    })
                    .to(playButton, {alpha: 0}, "<")
                    .to(clone, style);


                    tl.play();

                })
            }

            if (playButtonMobile) {
                let videoHeight = videoMobile.offsetHeight;

                const forward = mobileControls.querySelector('.forward')
                const backward = mobileControls.querySelector('.backwards')


                const mobileBAnim = gsap.to(playButtonMobile, {
                    // y: 150,
                    y: ()=>videoHeight/2.5,
                    scale: 0.8,
                    ease: 'power2.out',
                    paused: true,
                })

                playButtonMobile.addEventListener('click', (e) => {

                    videoHeight = videoMobile.offsetHeight;
                    gsap.set(mobileControls, {y: ()=>videoHeight/2.5})

                    mobileControls.classList.toggle('active');
                    playButtonMobile.classList.toggle('playing')

                    if (playButtonMobile.classList.contains('playing')) {
                        e.currentTarget.firstElementChild.innerText = 'Pause Video'
                        mobileBAnim.play()
                        videoMobile.play();
                    } else {
                        e.currentTarget.firstElementChild.innerText = 'Play Video'
                        mobileBAnim.reverse();
                        videoMobile.pause();
                    }
                })

                forward.addEventListener('click', ()=>{
                    videoMobile.currentTime += 15;
                })

                backward.addEventListener('click', ()=>{
                    videoMobile.currentTime -= 15;
                })

            }

            if (closeButton) {
                closeButton.addEventListener('click', (e) => {
                    closeButton.classList.remove('active');
                    resetAnim(video, tl)
                })
            }

            if (video) {
                ScrollTrigger.create({
                    trigger: video,
                    start: '-10 top',
                    end: '51% center',
                    // markers: true,
                    onLeave: () => {
                        closeButton.classList.remove('active');
                        resetAnim(video, tl);
                    },
                    onLeaveBack: () => {
                        closeButton.classList.remove('active');
                        resetAnim(video, tl);
                    },
                })
            }

        })

    }
}