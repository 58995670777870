import { ScrollTrigger, gsap } from 'gsap/all';

function toggleClasses(classes, elem) {
    let toReturn;
    if(elem.classList.contains('icon__white')){
        toReturn = 'icon__white';
        elem.classList.remove('icon__white')
    }else if(elem.classList.contains('icon__black')){
        toReturn = 'icon__black';
        elem.classList.remove('icon__black')
    }

    classes.forEach(myClass => elem.classList.toggle(myClass))
    return toReturn;
}

export default function navColor() {

    const triggers = document.querySelectorAll('.nav-color-trigger');
    const mainHeader = document.getElementById('main-header');

    if (triggers.length > 0) {

        triggers.forEach(el => {
            const iconC = el.getAttribute('data-icon-color') ? `icon__${el.getAttribute('data-icon-color')}` : 'icon--default';
            const logoC = el.getAttribute('data-logo-color') ? `logo__${el.getAttribute('data-logo-color')}` : 'logo--default';
            let previousState;


            ScrollTrigger.create({
                trigger: el,
                start: 'top top',
                onEnter: ()=>{
                    previousState = toggleClasses([iconC, logoC], mainHeader);
                },
                onLeaveBack: ()=>{toggleClasses([previousState, logoC], mainHeader)},
                // onLeave: ()=>{toggleClasses([iconC, logoC], mainHeader)},
                // onEnterBack: ()=>{toggleClasses([iconC, logoC], mainHeader)},
            })

        })
    }

}