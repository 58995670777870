import { gsap } from 'gsap/all';

export default function services() {

    const services = document.querySelector('.services-template')

    if(services){
        gsap.set('.service-intro #service-intro-heading', {alpha: 0})
        gsap.set('.service-intro .content p', {alpha: 0})
        gsap.set('.service-intro .text-button-text', {alpha: 0})


        gsap.to('.service-intro .text-button-text', {alpha: 1, delay: 1})
        gsap.to('.service-intro #service-intro-heading', {alpha: 1, delay: .8})
        gsap.to('.service-intro .content p', {alpha: 1, delay: .85})

        gsap.from('#service-description .container', {
            alpha: 0,
            yPercent: 6,
            ease: 'power3.out',
            scrollTrigger: {
                trigger: '#service-description',
                start: 'top 70%',
                toggleActions: 'play none none reverse'
            } 
        })
    }



}