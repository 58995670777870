import { gsap, ScrollTrigger } from 'gsap/all';

import panelTwo from './packaging/panel-two';
import panelThree from './packaging/panel-three';
import panelFour from './packaging/panel-four';
import panelFive from './packaging/panel-five';
import packagingFrame from './packaging/packaging-frames';

export default function packaging() {
  const packagingDom = document.querySelector('.packaging-template');

  if (packagingDom) {

    const mmQuery = '(min-width: 800px)';
    const mmQueryMobile = '(max-width: 799px)';

    packagingFrame(packagingDom, mmQuery, mmQueryMobile);
    panelTwo(packagingDom, mmQuery, mmQueryMobile);
    panelThree(packagingDom, mmQuery, mmQueryMobile);
    panelFour(packagingDom, mmQuery, mmQueryMobile);
    panelFive(packagingDom, mmQuery, mmQueryMobile);

    const wrapper = document.querySelector('.packaging-pannels .wrapper');
    const wrapperHeight = wrapper.offsetHeight;
    const panels = packagingDom.querySelectorAll('.panel');
    const dotsDom = packagingDom.querySelector('#dots-nav');
    const dots = packagingDom.querySelectorAll('#dots-nav .dot');
    const scrollIconContainer = packagingDom.querySelectorAll('.scroll-to-container');
    const scrollIcon = packagingDom.querySelector('.scroll-to');

    const mm = gsap.matchMedia()

    panels.forEach((panel, i) => {


      const panelHeight = panel.offsetHeight;
      const dot = dots[i];
      const start = i > 0 ? '50%' : 'top';

      mm.add(mmQuery, () => {
        gsap.to(dot, {
          height: 75,
          borderRadius: '10px',
          alpha: 1,
          scrollTrigger: {
            trigger: panel,
            toggleActions: 'play reverse play reverse',
            start: `top ${start}`,
            end: `${panelHeight} 50%`,
            // markers: true,
          }
        })

        if (i === 2) {
          gsap.to(dots, {
            backgroundColor: '#000',
            duration: 0.3,
            ease: 'linear',
            scrollTrigger: {
              trigger: panel,
              toggleActions: 'play reverse play reverse',
              start: 'top 50%',
              end: `${panelHeight} 50%`,
              // markers: true,
            }
          })
        }

      })
    })

    dots.forEach((dot, i) => {
      dot.addEventListener('click', () => {
        gsap.to(window, {
          duration: 1.4,
          ease: 'power3.out',
          scrollTo: { y: panels[i], offsetY: -10 }
        })
      })
    })


    ScrollTrigger.create({
      trigger: dotsDom,
      start: `top 35%`,
      end: `${wrapperHeight} top`,
      pinSpacing: false,
      anticipatePin: 1,
      pin: true,
      // markers: true
    })

    gsap.to(scrollIconContainer, {
      y: 300,
      scrollTrigger: {
        trigger: 'body',
        start: `top top`,
        end: `bottom bottom`,
        scrub: 1.5,
        // markers: true,
      }
    })

    scrollIcon.addEventListener('click', () => {
      gsap.to(window, {
        duration: 1,
        ease: 'power3.out',
        scrollTo: { y: panels[0], offsetY: -10 }
      })
    })

  }

}
